import { ICustomerPortalTheme, IModernThemeSettings } from "@smartrr/shared/entities/CustomerPortalTheme";
import { ISellingPlanGroupUpdate, SellingPlanGroupApiResponse } from "@smartrr/shared/entities/SellingPlanGroup";
import { isEqual } from "lodash";
import { WithResult } from "@smartrr/shared/types";
import { captureException } from "@smartrr/shared/utils/captureException";
import { useMemo } from "react";
import { create } from "zustand";

import { fullShopifyIdsToNumericIds, numericIdsToFullShopifyIds } from "@vendor-app/utils/numericIdsMapper";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { immer } from "zustand/middleware/immer";

export type TrendingSectionThemeValues = IModernThemeSettings["sections"]["trending"];
interface ITrendingListStore {
  config: SellingPlanGroupApiResponse | null | undefined;
  input: SellingPlanGroupApiResponse | null | undefined;

  isLoading: boolean;

  theming: {
    hasThemeChanges: boolean;
    theme: ICustomerPortalTheme | null;
    initialTrendingSectionThemeValues: TrendingSectionThemeValues | null;
    trendingSectionThemeValues: TrendingSectionThemeValues | null;
    errors: {
      renderToast: boolean;
      toastMessage: string;
    };
  };

  actions: {
    fetchTrendingListConfig: () => Promise<WithResult<SellingPlanGroupApiResponse>>;
    createTrendingList: () => Promise<WithResult<SellingPlanGroupApiResponse>>;
    updateTrendingList: (
      sellingPlanGroup: ISellingPlanGroupUpdate
    ) => Promise<WithResult<SellingPlanGroupApiResponse>>;
    initializeTrending: () => Promise<void>;
    theming: {
      discard: () => void;
      get: () => Promise<void>;
      initializeTrendingSectionThemeValues: () => void;
      resetError: () => void;
      setError: (errorMessage: string) => void;
      update: (themeId: number, updatedThemeProperties: ICustomerPortalTheme) => Promise<void>;
      updateHasThemeChanges: (hasChanges: boolean) => void;
      updateTrendingSectionThemeValues: (newValues: TrendingSectionThemeValues) => void;
    };
  };
  internal: {
    initializeConfig: (newConfig: SellingPlanGroupApiResponse | null | undefined) => void;
    whileLoading<Type>(fn: () => Promise<Type>): Promise<Type>;
  };
}

const useTrendingListConfig = create<ITrendingListStore>()(
  immer((set, get) => ({
    config: null,
    input: null,

    isLoading: false,

    theming: {
      hasThemeChanges: false,
      isThemeLoading: false,
      theme: null,
      initialTrendingSectionThemeValues: null,
      trendingSectionThemeValues: null,
      errors: {
        renderToast: false,
        toastMessage: "",
      },
    },

    actions: {
      async fetchTrendingListConfig() {
        return get().internal.whileLoading(async () => {
          try {
            const data = await typedFrontendVendorApi.getReq("/selling-plan-groups/trending-list");

            if (data.type === "success") {
              const productIds = numericIdsToFullShopifyIds(data.body.productIds, "Product");
              const productVariantIds = numericIdsToFullShopifyIds(data.body.productVariantIds, "ProductVariant");

              get().internal.initializeConfig({
                ...data.body,
                productIds,
                productVariantIds,
              });

              return {
                result: "success",
                data: {
                  ...data.body,
                  productIds,
                  productVariantIds,
                },
              };
            }

            get().internal.initializeConfig(undefined);
            return {
              result: "failure",
              message: data.message,
            };
          } catch (error) {
            captureException("Failed to fetch addons config", error);

            return {
              result: "failure",
              message: "Failed to fetch addons config",
            };
          }
        });
      },

      async createTrendingList() {
        if (get().config !== undefined) {
          return {
            result: "failure",
            message: "Please get the config first",
          };
        }
        return get().internal.whileLoading(async () => {
          try {
            const createdSellingPlanGroupRes = await typedFrontendVendorApi.postReq(
              "/selling-plan-groups/trending-list"
            );

            if (createdSellingPlanGroupRes.type === "success") {
              const productIds = numericIdsToFullShopifyIds(
                createdSellingPlanGroupRes.body.productIds,
                "Product"
              );
              const productVariantIds = numericIdsToFullShopifyIds(
                createdSellingPlanGroupRes.body.productVariantIds,
                "ProductVariant"
              );

              get().internal.initializeConfig({
                ...createdSellingPlanGroupRes.body,
                productIds,
                productVariantIds,
              });

              return {
                result: "success",
                data: {
                  ...createdSellingPlanGroupRes.body,
                  productIds,
                  productVariantIds,
                },
              };
            }

            return {
              result: "failure",
              message: createdSellingPlanGroupRes.message,
            };
          } catch (error) {
            captureException("Failed to create selling plan group", error);

            return {
              result: "failure",
              message: "Failed to create selling plan group",
            };
          }
        });
      },

      async updateTrendingList(sellingPlanGroup: ISellingPlanGroupUpdate) {
        return get().internal.whileLoading(async () => {
          try {
            const updatedSellingPlanGroupRes = await typedFrontendVendorApi.putReq(
              "/selling-plan-groups/trending-list",
              {
                reqBody: {
                  ...sellingPlanGroup,
                  productIds: fullShopifyIdsToNumericIds(sellingPlanGroup.productIds ?? []),
                  productVariantIds: fullShopifyIdsToNumericIds(sellingPlanGroup.productVariantIds ?? []),
                },
              }
            );

            if (updatedSellingPlanGroupRes.type === "success") {
              const productIds = numericIdsToFullShopifyIds(
                updatedSellingPlanGroupRes.body.productIds,
                "Product"
              );
              const productVariantIds = numericIdsToFullShopifyIds(
                updatedSellingPlanGroupRes.body.productVariantIds,
                "ProductVariant"
              );

              get().internal.initializeConfig({
                ...updatedSellingPlanGroupRes.body,
                productIds,
                productVariantIds,
              });

              return {
                result: "success",
                data: {
                  ...updatedSellingPlanGroupRes.body,
                  productIds,
                  productVariantIds,
                },
              };
            }

            return {
              result: "failure",
              message: updatedSellingPlanGroupRes.message,
            };
          } catch (error) {
            captureException("Failed to update selling plan group", error);

            return {
              result: "failure",
              message: "Failed to update selling plan group",
            };
          }
        });
      },

      async initializeTrending() {
        const actions = get().actions;

        await actions.fetchTrendingListConfig();
        await actions.theming.get();
        actions.theming.initializeTrendingSectionThemeValues();
      },

      theming: {
        discard() {
          const { initialTrendingSectionThemeValues } = get().theming;
          set(draft => {
            draft.theming.trendingSectionThemeValues = initialTrendingSectionThemeValues;
            draft.theming.hasThemeChanges = false;
          });
        },

        async get() {
          const internal = get().internal;
          await internal.whileLoading(async () => {
            const response = await typedFrontendVendorApi.getReq("/customer-portal-theme");
            const actions = get().actions.theming;
            if (response.type === "error") {
              actions.setError("Error retrieving customer portal theme");
              set(draft => {
                draft.theming.hasThemeChanges = false;
              });
              return;
            }

            if (response.type !== "success") {
              actions.setError("Error retrieving customer portal theme");
              set(draft => {
                draft.theming.hasThemeChanges = false;
              });
              return;
            }

            if (response.type === "success") {
              set(draft => {
                if (response.body !== null) {
                  draft.theming.theme = response.body;
                  draft.theming.hasThemeChanges = false;
                }
              });

              actions.resetError();
            }
          });
        },

        async update(themeId, updatedThemeProperties) {
          const response = await typedFrontendVendorApi.postReq("/customer-portal-theme", {
            reqBody: {
              themeShopifyId: themeId,
              updatedThemeProperties,
            },
          });

          if (response.type === "error") {
            this.setError("Error sending customer portal theme updates");
            return;
          }

          if (response.type !== "success") {
            this.setError("Error sending customer portal theme updates");
            return;
          }

          if (response.type === "success") {
            set(draft => {
              draft.theming.theme = updatedThemeProperties;
            });
            this.resetError();
          }
        },

        updateHasThemeChanges(hasChanges) {
          set(draft => {
            draft.theming.hasThemeChanges = hasChanges;
          });
        },

        initializeTrendingSectionThemeValues() {
          const theme = get().theming.theme;
          if (theme) {
            const trending: TrendingSectionThemeValues | undefined =
              theme.modernThemeSettings?.sections?.trending;
            if (trending) {
              const { display, heading, caption, showHeart, imgUrl, sortMethod } = trending;
              set(draft => {
                draft.theming.initialTrendingSectionThemeValues = {
                  display,
                  heading,
                  caption,
                  showHeart,
                  imgUrl,
                  sortMethod,
                };
                draft.theming.trendingSectionThemeValues = {
                  display,
                  heading,
                  caption,
                  showHeart,
                  imgUrl,
                  sortMethod,
                };
              });
            }
          }
        },

        resetError() {
          set(draft => {
            draft.theming.errors.renderToast = false;
            draft.theming.errors.toastMessage = "";
          });
        },

        setError(errorMessage: string) {
          set(draft => {
            draft.theming.errors.renderToast = true;
            draft.theming.errors.toastMessage = errorMessage;
          });
        },

        updateTrendingSectionThemeValues(newValues) {
          const initialTrendingSectionThemeValues = get().theming.initialTrendingSectionThemeValues;
          set(draft => {
            draft.theming.trendingSectionThemeValues = newValues;
          });

          if (isEqual(initialTrendingSectionThemeValues, newValues)) {
            set(draft => {
              draft.theming.hasThemeChanges = false;
            });
          } else {
            set(draft => {
              draft.theming.hasThemeChanges = true;
            });
          }
        },
      },
    },

    internal: {
      initializeConfig(newConfig: SellingPlanGroupApiResponse | null | undefined) {
        set({
          config: newConfig,
          input: newConfig,
        });
      },

      async whileLoading<Type>(fn: () => Promise<Type>): Promise<Type> {
        set({
          isLoading: true,
        });

        const result = await fn();

        set({
          isLoading: false,
        });

        return result;
      },
    },
  }))
);

export const TrendingListConfigAccess = {
  useLoading: () => useTrendingListConfig(state => state.isLoading),
  useConfig: () => useTrendingListConfig(state => state.config),
  useActions: () => useTrendingListConfig(state => state.actions),
  useNotCreated() {
    const config = useTrendingListConfig(state => state.config);
    return useMemo(() => config === undefined, [config]);
  },

  useErrors() {
    return useTrendingListConfig(state => state.theming.errors);
  },

  useHasThemeChanges() {
    return useTrendingListConfig(state => state.theming.hasThemeChanges);
  },

  useTheme() {
    return useTrendingListConfig(state => state.theming.theme);
  },

  useInitialTrendingSectionThemeValues() {
    return useTrendingListConfig(state => state.theming.initialTrendingSectionThemeValues);
  },

  useTrendingSectionThemeValues() {
    return useTrendingListConfig(state => state.theming.trendingSectionThemeValues);
  },

  testing: {
    initialState: useTrendingListConfig.getState(),
    reset() {
      useTrendingListConfig.setState(this.initialState);
    },
    state: useTrendingListConfig.getState,
    actions: useTrendingListConfig.getState().actions,
    store: useTrendingListConfig.getState(),
  },
};
